import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MembershipTierActions from "actions/membership-tier-actions";
import * as CartAbandonmentActions from "actions/cart-abandonment-actions";
import GTMHelper from "helpers/gtm";
import Converge from "helpers/converge";
import { configureSailthru, sendSailthruAddToCartEvent } from "helpers/third-party/sailthru-helpers";
import { sendMakeCheckoutEvents } from "helpers/ce-standard-checkout-analytics-helpers";
import { isAnonymous } from "./with-user-data";
import { analytics, membershipStyles } from "rtr-constants";
import { getDisplayName } from "./utils";

export function useSubscriptionAtbAnalytics() {
  const dispatch = useDispatch();

  const membershipTiers = useSelector(state => state.membershipTiers);
  const userData = useSelector(state => state.userData);
  const { userProfile = {} } = userData || {};
  const { id: userId, email } = userProfile ?? {};
  const sailthruConfig = useSelector(state => ({
    customerId: state.publicEnv?.sailthruCustomerId,
    url: state.initialUrl,
  }));
  const [shouldFireViewItemAnalytics, setShouldFireViewItemAnalytics] = useState(false);

  const trackSubscriptionCartAbandonment = () => {
    const dateAdded = new Date().toISOString();
    dispatch(CartAbandonmentActions.initializeCartAbandonmentState(dateAdded));
  };

  const handleGtmTracking = selectedTier => {
    GTMHelper.addToCartEvent(selectedTier, null, true, userId, email);
  };

  const handleSailthruTracking = selectedTier => {
    sendSailthruAddToCartEvent(selectedTier, email, null, analytics.SUBSCRIPTION);
  };

  const handleConvergeTracking = selectedTier => {
    Converge.trackAddedMembershipToCart(selectedTier);
  };

  const handleRtrPixels = selectedTier => {
    sendMakeCheckoutEvents("hp_ways_to_rent", membershipStyles.UPDATE, selectedTier, isAnonymous(userData));
  };

  useEffect(() => {
    if (!membershipTiers || !Array.isArray(membershipTiers)) {
      dispatch(MembershipTierActions.getMembershipTiers());
    }

    configureSailthru(sailthruConfig);
  }, []);

  useEffect(() => {
    if (membershipTiers && userData?.userProfileHasLoaded && shouldFireViewItemAnalytics) {
      const twoSwap = membershipTiers?.find(tier => tier.monthlyShipmentLimit === 2);

      const viewedPlanItem = {
        sku: membershipStyles.UPDATE,
        product: {
          displayName: twoSwap?.name,
          designer: { displayName: "Rent the Runway" },
          purchasePrice: twoSwap?.basePrice,
        },
        category: { id: "Subscription" },
        purchasePrice: twoSwap?.basePrice,
      };

      GTMHelper.viewPlansEvent(viewedPlanItem, userData?.userProfile?.id, userData?.userProfile?.email);

      setShouldFireViewItemAnalytics(false);
    }
  }, [membershipTiers, userData?.userProfileHasLoaded, shouldFireViewItemAnalytics]);

  const fireAtbAnalytics = tierId => {
    const selectedTier = membershipTiers?.find(membership => membership.id === tierId);

    trackSubscriptionCartAbandonment();
    handleGtmTracking(selectedTier);
    handleSailthruTracking(selectedTier);
    handleConvergeTracking(selectedTier);
    handleRtrPixels(selectedTier);
  };

  return [() => setShouldFireViewItemAnalytics(true), fireAtbAnalytics];
}

export function withSubscriptionAtbAnalytics(WrappedComponent) {
  const C = props => {
    const [fireViewItemAnalytics, fireAtbAnalytics] = useSubscriptionAtbAnalytics();

    return (
      <WrappedComponent
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
        sendSubscriptionViewItemAnalytics={fireViewItemAnalytics}
        sendSubscriptionAtbAnalytics={fireAtbAnalytics}
      />
    );
  };

  C.displayName = `withSubscriptionAtbAnalytics(${getDisplayName(WrappedComponent)})`;

  return C;
}
